import React, { Component } from 'react';

import EnquiryRequest from './EnquiryRequest';

import ConditionalButton from '../components/ConditionalButton';
import DemandTypes from '../components/DemandTypes';
import EmptyPanel from '../components/panels/EmptyPanel';
import EnquirySent from '../components/EnquirySent';
import FormGroup from '../components/FormGroup';
import InputField from '../components/InputField';
import Intro from '../components/Intro';
import PrimaryPanel from '../components/panels/PrimaryPanel';
import RemoveButton from '../components/RemoveButton';

import { TranslationContext } from '../translations/TranslationContext';

import background from './background.jpg';
import './Enquiries.css';

class Enquiries extends Component {
  constructor() {
    super();

    this.state = { sent: false };
    this.updateQuantity = this.updateQuantity.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  t = (id) => this.context.translate('enquiries.enquiries.' + id);

  updateQuantity(id, quantity) {
    this.props.enquiries.updateQuantity(id, quantity);
  }

  updateDemandType(id, demandType) {
    this.props.enquiries.updateDemandType(id, demandType);
  }

  removeEnquiry(id) {
    this.props.enquiries.remove(id);
  }

  handleFieldChange(fieldId, value) {
    var nextState = Object.assign({}, this.state);
    nextState[fieldId] = value;
    this.setState(nextState);
  }

  canSend() {
    return this.props.enquiries.count() > 0 && this.props.enquiries.valid();
  }

  onSend(subject, message) {
    this.props.backgroundQueue.enqueue(
      new EnquiryRequest(this.props.enquiries.getAll())
    );
    this.props.enquiries.removeAll();
    this.setState({ sent: true });
  }

  render() {
    return(
      <div className="Enquiries">
        <PrimaryPanel>
          <h2>{this.t('my_enquiries')}</h2>
          <Intro>
            {this.t('intro')}
          </Intro>
          <form onSubmit={(e) => { e.preventDefault(); this.onSend(); }}>
            <FormGroup>
              <h2>{this.t('product_information')}</h2>
              <p>
                {this.t('i_would_like_to_enquire')}
              </p>
              <table className="Results Enquiries-table">
                <thead>
                  <tr>
                    <th>{this.t('parker_part_number')}<span className="Results-sliver"></span></th>
                    <th>{this.t('quantity')}<span className="Results-sliver"></span></th>
                    <th colSpan="2">{this.t('demand_type')}<span className="Results-sliver"></span></th>
                  </tr>
                </thead>
                <tbody>
                  {this.enquiryRows()}
                </tbody>
              </table>
            </FormGroup>
            {this.sentMessage()}
            <ConditionalButton
              type="submit"
              text={this.t('send_enquiry')}
              condition={() => this.canSend()}
            />
          </form>
        </PrimaryPanel>
        <EmptyPanel background={background}/>
      </div>
    );
  }

  sentMessage() {
    if (this.state.sent) {
      return <EnquirySent />;
    } else {
      return null;
    }
  }

  enquiryRows() {
    let rows = [];
    for (let i = 0; i < this.props.enquiries.count(); i++) {
      const enquiry = this.props.enquiries.get(i);
      rows.push(
        <tr key={enquiry.id}>
          <td>{enquiry.parkerNumber}</td>
          <td>
            <InputField
              id={enquiry.id}
              value={enquiry.quantity}
              onChange={this.updateQuantity}
            />
          </td>
          <td>
            <DemandTypes demandType={enquiry.demandType} onChange={(e) => this.updateDemandType(enquiry.id, e.target.value)}/>
          </td>
          <td>
            <RemoveButton onRemove={() => this.removeEnquiry(enquiry.id)}/>
          </td>
        </tr>
      );
    }
    return rows;
  }
}

Enquiries.contextType = TranslationContext;

export default Enquiries;
