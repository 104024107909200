import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Badge from './Badge';
import Menu from './Menu';
import './Header.css';
import logo from './header-logo.svg';
import homeIcon from './home-icon.svg';
import notificationsIcon from './notifications-icon.svg';
import enquiriesIcon from './enquiries-icon.svg';
import contactIcon from './contact-icon.svg';
import menuIcon from './menu-icon.svg';
import menuCrossIcon from './menu-cross-icon.svg';

import { TranslationContext } from '../../translations/TranslationContext';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      menuActive: false
    };
  }

  t = (id) => this.context.translate('components.header.menu.' + id);

  enquiriesBadge() {
    return this.badge(this.props.enquiries.count());
  }

  notificationsBadge() {
    return this.badge(this.props.notifications.unreadNotificationsCount());
  }

  badge(count) {
    if (count) {
      return <Badge count={count}/>;
    } else {
      return null;
    }
  }

  render() {
    return (
      <header className="Header">
        <ul className="Header-menu">
          <li className="Header-rstripe">
            <Link to="/">
              <div className="Header-icon">
                <img className="Header-home-icon" alt="" src={homeIcon}/>
              </div>
              <span>{this.t('home')}</span>
            </Link>
          </li>
          <li className="Header-logo"><img src={logo} alt="ParFit Toolkit"/></li>
        </ul>
        <ul className="Header-menu HeaderRight">
          <li className="Header-notifications">
            <Link to="/notifications">
              <div className="Header-icon">
                <img className="Header-notifications-icon" alt="" src={notificationsIcon}/>
              </div>
              <span>{this.t('notifications')}</span>{this.notificationsBadge()}
            </Link>
          </li>
          <li>
            <Link to="/enquiries">
              <div className="Header-icon">
                <img className="Header-enquiries-icon" alt="" src={enquiriesIcon}/>
              </div>
              <span>{this.t('enquiries')}</span>{this.enquiriesBadge()}
            </Link>
          </li>
          <li className="Header-contact">
            <Link to="/contact">
              <div className="Header-icon">
                <img className="Header-contact-icon" alt="" src={contactIcon}/>
              </div>
              <span>{this.t('contact')}</span>
            </Link>
          </li>
          <li className="HeaderRight-cannot-find">
            <Link to="/cannot-find">{this.t('cannot_find')}</Link>
          </li>
          <li>
            <div onClick={() => this.toggleMenu()}>
              <div className="Header-icon">
                {this.menuIcon()}
              </div>
              <span>Menu</span>
            </div>
          </li>
        </ul>
        {this.menu()}
      </header>
    );
  }

  menuIcon() {
    if (this.state.menuActive) {
      return <img className="Header-menu-cross-icon" alt="" src={menuCrossIcon}/>;
    } else {
      return <img className="Header-menu-icon" alt="" src={menuIcon}/>;
    }
  }

  toggleMenu() {
    this.setState({ menuActive: !this.state.menuActive });
  }

  menu() {
    if (this.state.menuActive) {
      return (
        <Menu
          onClick={() => this.toggleMenu()}
          enquiries={this.props.enquiries.count()}
          notifications={this.props.notifications.unreadNotificationsCount()}
          />
      );
    } else {
      return null;
    }
  }
}

Header.contextType = TranslationContext;

export default Header;
