class NotificationsModel {
  constructor(api) {
    this.api = api;
    this.notifications = [];
  }

  fetchNotifications() {
    const model = this;

    this.api.fetchNotifications().then(function(data) {
      // Merge read/unread status into notifications retrieved from API.
      let read = model.read();
      model.notifications = data.notifications.map(n =>
        Object.assign({}, n, {
          read: read.includes(n.id),
          urgent: n.urgent,
          htmlContent: () => ({__html: n.content})
        })
      );
      model.onChange(model);
    }).catch(err => console.error('Could not fetch notifications', err));
  }

  urgentNotifications() {
    return this.unreadNotifications().filter(n => n.urgent);
  }

  unreadNotificationsCount() {
    return this.unreadNotifications().length;
  }

  unreadNotifications() {
    return this.notifications.filter(n => !n.read);
  }

  readNotifications() {
    return this.notifications.filter(n => n.read);
  }

  // Marks a notification as read and persists the read status.
  markRead(notificationId) {
    this.setReadStatus(notificationId, true);
  }

  // Marks a notification as unread and persists the read status.
  markUnread(notificationId) {
    this.setReadStatus(notificationId, false);
  }

  setReadStatus(notificationId, status) {
    let idx = this.notifications.findIndex(n => n.id === notificationId);
    if (idx !== -1) {
      this.notifications[idx].read = status;
      this.persistReadStatus();
      this.onChange(this);
    }
  }

  // Returns an array containing ids of read notifications.
  read() {
    return JSON.parse(localStorage.getItem('readNotifications') || "[]");
  }

  persistReadStatus() {
    let read = this.notifications.filter(n => n.read).map(n => n.id);
    localStorage.setItem('readNotifications', JSON.stringify(read));
  }
}

export default NotificationsModel;
