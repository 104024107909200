import React, { Component } from 'react';

import { TranslationContext } from '../translations/TranslationContext';

import './EnquirySent.css';

class EnquirySent extends Component {
  t = (id) => this.context.translate('components.enquiry_sent.' + id);

  render() {
    let message = this.t('sent');
    return (
      <div className="EnquirySent">
        <p><strong>{message}</strong></p>
      </div>
    );
  }
}

EnquirySent.contextType = TranslationContext;

export default EnquirySent;
