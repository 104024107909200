import React, { Component } from 'react';

import "./Modal.css"

class Modal extends Component {
  render() {
    return (
      <div className="Modal">
        <div className="Modal-innerContainer">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Modal
