import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Header from './components/header/Header';
import HeaderMinimal from './components/header/HeaderMinimal';
import Modal from './components/Modal';

import Contact from './contact/Contact';

import Datasheets from './datasheets/Datasheets';
import DatasheetRepository from './data/DatasheetRepository';

import IdGenerator from './IdGenerator';
import Enquiries from './enquiries/Enquiries';
import EnquiriesModel from './enquiries/EnquiriesModel';
import CannotFind from './cannot_find/CannotFind';

import Notifications from './notifications/Notifications';
import NotificationsModel from './notifications/NotificationsModel';

import Auth from './account/Auth';
import EngineerRepository from './account/EngineerRepository';
import Register from './account/Register';
import ResetPassword from './account/ResetPassword';
import ResetPasswordEmail from './account/ResetPasswordEmail';
import SignIn from './account/SignIn';
import SignInOrReset from './account/SignInOrReset';
import VideoTutorial from './video_tutorial/VideoTutorial';
import Information from './information/Information';
import Language from './language/Language';

import Home from './home/Home';
import ElementExpert from './element_expert/ElementExpert';
import Dimensions from './element_expert/Dimensions';
import ElementSelector from './element_selector/ElementSelector';
import OEMApps from './oem_apps/OEMApps';
import MediaInfo from './media_info/MediaInfo';
import ProductInfo from './product_info/ProductInfo';
import TypeViewer from './type_viewer/TypeViewer';

// Data providers.
import API from './data/API';
import BackgroundQueue from './data/BackgroundQueue';
// Each pair of repositories conform to the same interface.
import ElementExpertRepository from './data/ElementExpertRepository';
import ElementSelectorRepository from './data/ElementSelectorRepository';
import OEMRepository from './data/OEMRepository';
import ProductRepository from './data/ProductRepository';
import ElementImageRepository from './data/ElementImageRepository';
import StyleImageRepository from './data/StyleImageRepository';
import TypeImageRepository from './data/TypeImageRepository';

import { TranslationContext } from './translations/TranslationContext';
import Translation from './translations/Translation';
import Translations from './translations/Translations';

import './App.css';

class App extends Component {
  constructor() {
    super();

    this.onUnauthorized = this.onUnauthorized.bind(this);

    this.api = new API(this.onUnauthorized);
    this.backgroundQueue = new BackgroundQueue(
      this.api, this.localStorage(), 15
    );

    let enquiries = new EnquiriesModel(new IdGenerator(), this.localStorage())
    let notifications = new NotificationsModel(this.api);

    enquiries.onChange = (e) => this.onEnquiriesChange(e);
    notifications.onChange = (e) => this.onNotificationsChange(e);

    this.auth = new Auth(new EngineerRepository(this.localStorage()), this.api);

    if (this.auth.signedIn()) {
      notifications.fetchNotifications();
    }

    this.state = {
      translation: new Translation(),
      token: this.auth.getToken(),
      enquiries: enquiries,
      notifications: notifications,
      db: null,
      repos: this.onlineRepositories(this.api)
    };
  }


  componentDidMount() {
    const locale = this.localStorage().getItem('locale') || 'en_GB';
    console.log('setting initial locale', locale);
    this.setState({ locale })
    this.loadTranslation(locale);
  }


  loadTranslation(locale) {
    console.log('loadTranslation', locale);
    this.state.repos.translations
      .fetchTranslation(locale)
      .then(translation => this.setState({ translation: new Translation(translation) }))
      .catch((error) => {
        alert('Translations could not be loaded. Using English.')
      });
  }

  onSetLocale(locale) {
    console.log('onSetLocale', locale);
    this.localStorage().setItem('locale', locale);
    this.setState({ locale });
    this.loadTranslation(locale);
  }

  localStorage() {
    return window.localStorage || {
      getItem: function (key) {
        return this[key];
      },
      setItem: function (key, value) {
        this[key] = value;
      },
      removeItem: function (key) {
        this[key] = null;
      }
    };
  }

  // Returns a set of repositories that are backed by the web API. These
  // require an Internet connection.
  onlineRepositories(api) {
    return {
      datasheets: new DatasheetRepository(api),
      elementExpert: new ElementExpertRepository(api),
      elementImages: new ElementImageRepository(api),
      elementSelector: new ElementSelectorRepository(api),
      oems: new OEMRepository(api),
      products: new ProductRepository(api),
      styleImages: new StyleImageRepository(api),
      translations: new Translations(api),
      typeImages: new TypeImageRepository(api)
    };
  }

  onEnquiriesChange(enquiries) {
    this.setState({ enquiries: enquiries });
  }

  onNotificationsChange(notifications) {
    this.setState({ notifications: notifications });
  }

  onUnauthorized() {
    this.onSignOut();
  }

  onSignIn(token) {
    this.state.notifications.fetchNotifications();
    this.setState({
      token: token
    });
  }

  onSignOut() {
    this.auth.setToken(null);
    this.setState({
      token: null
    });
  }

  render() {
    return (
      <TranslationContext.Provider value={this.state.translation}>
        {this.app()}
      </TranslationContext.Provider>
    );
  }

  app() {
    if (this.state.token) {
      return this.appSignedIn();
    } else {
      return this.appSignedOut();
    }
  }

  appSignedIn() {
    const pendingNotification = this.state.notifications.urgentNotifications()[0];

    const className = 'App';

    return (
      <div className="AppContainer">
        {pendingNotification &&
          <Modal>
            <div>
              <h1>{pendingNotification.title}</h1>
              <p>{pendingNotification.content}</p>
              <button onClick={() => this.state.notifications.markRead(pendingNotification.id)}>Accept</button>
            </div>
          </Modal>
        }
        <div className={className}>
          <Header
            enquiries={this.state.enquiries}
            notifications={this.state.notifications}
          />
          <Route exact path="/" render={(routeProps) => (
            <Home {...routeProps}
              onSignOut={() => this.onSignOut()}
            />
          )}/>
          <Route path="/contact" render={(routeProps) => (
            <Contact {...routeProps}
              backgroundQueue={this.backgroundQueue}
            />
          )}/>

          <Route path="/enquiries" render={(routeProps) => (
            <Enquiries {...routeProps}
              backgroundQueue={this.backgroundQueue}
              enquiries={this.state.enquiries}
            />
          )}/>

          <Route path="/cannot-find" render={(routeProps) => (
            <CannotFind {...routeProps}
              backgroundQueue={this.backgroundQueue}
            />
          )}/>

          <Route path="/notifications" render={(routeProps) => (
            <Notifications {...routeProps}
              notifications={this.state.notifications}
            />
          )}/>

          <Route path="/element-selector" render={(routeProps) => (
            <ElementSelector {...routeProps}
              datasheets={this.state.repos.datasheets}
              elementExpert={this.state.repos.elementExpert}
              elementImages={this.state.repos.elementImages}
              elementSelector={this.state.repos.elementSelector}
              enquiries={this.state.enquiries}
            />
          )}/>

          <Route exact path="/element-expert" render={(routeProps) => (
            <ElementExpert {...routeProps}
              datasheets={this.state.repos.datasheets}
              elementExpert={this.state.repos.elementExpert}
              elementImages={this.state.repos.elementImages}
              styleImages={this.state.repos.styleImages}
              typeImages={this.state.repos.typeImages}
              enquiries={this.state.enquiries}
            />
          )}/>
          <Route path="/element-expert/:id/dimensions" render={(routeProps) => (
            <Dimensions {...routeProps}
              datasheets={this.state.repos.datasheets}
              enquiries={this.state.enquiries}
              elementExpert={this.state.repos.elementExpert}
              elementImages={this.state.repos.elementImages}
              styleImages={this.state.repos.styleImages}
            />
          )}/>

          <Route path="/oem-apps" render={(routeProps) => (
            <OEMApps {...routeProps}
              repos={this.state.repos}
              enquiries={this.state.enquiries}
            />
          )}/>

          <Route path="/product-info/:parkerNumber" render={(routeProps) => (
            <ProductInfo {...routeProps}
              datasheets={this.state.repos.datasheets}
              elementExpert={this.state.repos.elementExpert}
              elementImages={this.state.repos.elementImages}
              enquiries={this.state.enquiries}
            />
          )}/>

          <Route path="/type-viewer/:id" render={(routeProps) => (
            <TypeViewer {...routeProps}
              typeImages={this.state.repos.typeImages}
            />
          )}/>

          <Route path="/media-info/:parkerNumber" render={(routeProps) => (
            <MediaInfo {...routeProps}
              elementExpert={this.state.repos.elementExpert}
            />
          )}/>

          <Route path="/datasheets" render={(routeProps) => (
            <Datasheets {...routeProps} datasheets={this.state.repos.datasheets} />
          )}/>

          <Route path="/video-tutorial" component={VideoTutorial}/>
          <Route path="/information" component={Information}/>
          <Route path="/language" render={(routeProps) => (
            <Language {...routeProps}
              locale={this.state.locale}
              onSetLocale={(locale) => this.onSetLocale(locale)}
              />
          )}/>
        </div>
      </div>
    );
  }

  appSignedOut() {
    return (
      <div className="AppContainer">
        <div className="App">
          <HeaderMinimal/>
          <Switch>
            <Route path="/account/sign-in-or-reset" component={SignInOrReset}/>
            <Route path="/account/register" render={(routeProps) => (
              <Register {...routeProps}
                api={this.api}
              />
            )}/>
            <Route path="/account/reset-password/:email" render={(routeProps) => (
              <ResetPasswordEmail {...routeProps} />
            )}/>
            <Route path="/account/reset-password" render={(routeProps) => (
              <ResetPassword {...routeProps}
                api={this.api}
              />
            )}/>
            <Route path="/language" render={(routeProps) => (
              <Language {...routeProps}
                locale={this.state.locale}
                onSetLocale={(locale) => this.onSetLocale(locale)}
                />
            )}/>
            <Route render={(routeProps) => (
              <SignIn {...routeProps}
                auth={this.auth}
                onSignIn={(token) => this.onSignIn(token)}
              />
            )}/>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
